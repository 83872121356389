import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-currency-icon',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './currency-icon.component.html',
  styleUrls: ['./currency-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyIconComponent {
  iconClass = input<string>();

  customIcon = input<string>();
  type = input<'primary' | 'secondary'>('primary');
  icon = computed<string>(() => {
    return this.customIcon() || `coin-${this.type()}`;
  });
}
